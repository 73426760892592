import React, { ReactNode } from 'react'

import { ChartBarIcon } from '@heroicons/react/24/solid'
import { InputLabel, LinearProgress, Skeleton } from '@mui/material'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { calculatePercentageOf } from 'api/src/common/utils'
import { clamp } from 'ramda'

interface QuotaCardProps {
  loading: boolean
  limit: number
  usage: number
  children: ReactNode
}

const QuotaCard: React.FC<QuotaCardProps> = ({
  children,
  loading,
  limit,
  usage,
}) => {
  const hasNoQuotaRemaining = usage >= limit

  return (
    <Paper
      variant={'outlined'}
      style={{
        borderRadius: '10px',
        padding: '5px 10px',
      }}
      className={'text-sm font-light'}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        style={{
          display: 'flex',
          height: '100%',
        }}
      >
        {loading ? (
          <Skeleton variant="circular" width={30} height={24} />
        ) : (
          <ChartBarIcon className={'h-6 w-6 text-gray-400'} />
        )}
        {!loading && <Stack direction="row">{children}</Stack>}
        {loading ? (
          <Skeleton height={10} width="80%" />
        ) : (
          <InputLabel>Monthly Usage</InputLabel>
        )}
        {loading ? (
          <Skeleton height={10} width="40%" />
        ) : (
          <>
            <LinearProgress
              variant="determinate"
              value={clamp(0, 100, calculatePercentageOf(0, usage, limit))}
              color={!hasNoQuotaRemaining ? 'primary' : 'secondary'}
              style={{
                flexGrow: 1,
                backgroundColor: '#e0e0e0', // gray background color for LinearProgress
              }}
              className={`${
                !hasNoQuotaRemaining ? 'text-indigo-600' : 'text-orange-600'
              } h-2 rounded`}
            />
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <InputLabel>
              {usage} / {limit}
            </InputLabel>
          </>
        )}
      </Stack>
    </Paper>
  )
}

export default QuotaCard
