import React, { FC, ReactNode, useEffect, useState } from 'react'

import { ApolloQueryResult } from '@apollo/client'
import { captureException } from '@sentry/browser'
import { Exact, GetQuota, GetQuotaVariables } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import QuotaCard from 'src/lib/hooks/Quota/QuotaCard'

interface UseQuotaProps {
  feature: string
}

export type ReloadQuota = (
  variables?: Partial<
    Exact<{
      name: string
    }>
  >,
) => Promise<ApolloQueryResult<GetQuota>>

const GET_QUOTA = gql`
  query GetQuota($name: String!) {
    clientFeature: clientFeatureByFeatureName(name: $name) {
      usage
      limit
      feature {
        name
      }
      behaviour
    }
  }
`

const useQuota = ({ feature }: UseQuotaProps) => {
  const [quota, setQuota] = React.useState<GetQuota['clientFeature']>()
  const [isComponentLoading, setComponentIsLoading] = useState(true)

  const {
    loading: queryLoading,
    error: queryError,
    refetch: reloadQuota,
  } = useQuery<GetQuota, GetQuotaVariables>(GET_QUOTA, {
    variables: { name: feature },
    onCompleted: ({ clientFeature }) => {
      setQuota(clientFeature)
    },
    onError: (error) => {
      captureException(error)
    },
  })

  const [hasError, setHasError] = useState(!!queryError)

  useEffect(() => {
    if (quota) {
      setComponentIsLoading(false)
    }
    if (queryError) {
      setHasError(true)
    }

    setIsUsageBlocked(checkUsageBlocked())
  }, [quota, queryError])

  const checkUsageBlocked = () => {
    if (quota) {
      if (quota.behaviour === 'DISABLE') {
        return quota.usage >= quota.limit
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const [isUsageBlocked, setIsUsageBlocked] = useState(checkUsageBlocked())
  interface Props {
    children?: ReactNode
  }

  const QuotaComponent: FC<Props> = ({ children }) => {
    return (
      <>
        {hasError && <div>Error occurred...</div>}
        {!hasError && quota && quota.behaviour !== 'SILENT' && (
          <QuotaCard
            loading={isComponentLoading || queryLoading || !!queryError}
            limit={quota.limit}
            usage={quota.usage}
          >
            {children}
          </QuotaCard>
        )}
      </>
    )
  }

  return {
    quota,
    QuotaComponent,
    isUsageBlocked,
    reloadQuota,
  }
}
export default useQuota
